<template>
  <v-container v-if="getSelectedUser" fluid>
    <v-row>
      <v-col cols="12" md="6" class="d-flex align-center justify-start">
        <app-bar-return />
        <div>
          <new-app-bar-route
            :title="$helpers.getUserName(getSelectedUser)"
            :subtitle="$trans('section_admin_panel_clients')"
          />
          <div>
            <v-chip
              small
              :color="$helpers.getStatusColor(getSelectedUser.status).color"
              :class="$helpers.getStatusColor(getSelectedUser.status).class"
            >
              <v-icon left>$check</v-icon>
              {{
                isUserActive
                  ? $trans("active_account")
                  : $trans("inactive_account")
              }}
            </v-chip>
          </div>
          <div v-if="$vuetify.breakpoint.smAndUp" class="ml-2">
            <span class="text-caption light--text mr-2">
              {{ $trans("created_at") }}:
              {{
                getSelectedUser.created_at
                  | dateTime($helpers.hourDayMonthAndYearDate)
              }}
            </span>
            <template v-if="$route.name !== 'employee'">
              <br v-if="$vuetify.breakpoint.smAndDown" />
              <span
                class="text-caption light--text"
                v-text="emailVerification"
              />
            </template>
          </div>
        </div>
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="d-flex justify-start justify-md-end align-center"
      >
        <user-app-bar-menu class="float-right" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NewAppBarRoute from "@/views/dashboard/components/AppBar/AppBarComponents/NewAppBarRoute.vue";
import { mapGetters } from "vuex";

export default {
  name: "UserAppBar",
  components: {
    NewAppBarRoute,
    UserAppBarMenu: () => import("../AppBarComponents/UserAppBarMenu"),
    AppBarReturn: () => import("../AppBarComponents/AppBarReturn"),
  },
  computed: {
    ...mapGetters({
      getSelectedUser: "user/getSelectedUser",
    }),
    emailVerification() {
      const verified = ` ${this.$trans("email_verified_at")}: ${this.$moment(
        this.getSelectedUser.email_verified_at
      ).format(this.$helpers.dateTimeFormat)}`;
      const unverified = `${this.$trans("email_unverified")}`;
      return this.getSelectedUser.email_verified_at ? verified : unverified;
    },
    isUserActive() {
      return (
        this.getSelectedUser &&
        this.getSelectedUser.status === this.$helpers.userStatus.ACTIVE
      );
    },
  },
};
</script>
